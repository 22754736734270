import React from 'react'
import SbEditable from 'storyblok-react'
import Box from '@material-ui/core/Box'
import renderBloks from '@renderBloks'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import ACC_THEME from '@themes'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.background.slate,
    paddingTop: (props) => props.isCustomersModule && '0px',
    paddingLeft: (props) => !props.isCustomersModule && '64px',
    '& p': { lineHeight: 1.8, margin: '1rem 0' },
    paddingBottom: (props) => props.isCustomersModule && '60px',
  },
}))
const MarkdownModule = (props) => {
  const classes = useStyles(props)
  return (
    <SbEditable content={props.blok}>
      <PageContainer parentWidth={props.isCustomersModule}>
        <ContentContainer parentWidth={props.isCustomersModule}>
          <ThemeProvider theme={ACC_THEME}>
            <Box className={classes.root} pt={17} pb={10}>
              {renderBloks(props.blok.markdown)}
            </Box>
          </ThemeProvider>
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default MarkdownModule
